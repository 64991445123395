import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { createProject, uploadImage, saveInfo } from '@/actions/project'
import { useNavigate } from 'react-router-dom'
import { checkImageType, ERROR_NOT_ALLOWED_IMAGE } from '@/utils/upload.utils'
import { saveError } from '@/utils/notify.utils'
import { STORAGE_URL } from '@/constants/api'
import ImageLoader from '@/components/Common/Loader/ImageLoader'

import { infoSchema } from '@/validations/project.schema'

import ConfirmButton from '@/components/Common/Edit/ConfirmButton'

import './styles.css'

const UploadImage = ({ image, type, btnText, projectId, mode, onImageUpload }) => {
	const dispatch = useDispatch()
    const imageUploadInputRef = useRef()

    const [currentImage, setCurrentImage] = useState(null)
    const [tempImage, setTempImage] = useState(null)
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
    	if (image) {
    		setCurrentImage(image)
    	}
    }, [image])

    const handleImageUploadClick = () => {
        // setImageError(null)
        imageUploadInputRef.current.click()
    }

    const handleImageUpload = async (e) => {
        const image = e.target.files[0]
        if (checkImageType(image.type)) {
    		setLoaded(false)
            if (mode === 'edit') {
				const formData = new FormData()
				formData.append('type', type)
				formData.append('image', image)
				formData.append('project_id', projectId)
				const imageData = await dispatch(uploadImage(formData, projectId))
				setCurrentImage(`url(${STORAGE_URL}/${imageData.image})`)
				setTimeout(() => setLoaded(true), 500)
    		} else {
    			setTempImage(URL.createObjectURL(image))
    			onImageUpload({ type, image })
    			setTimeout(() => setLoaded(true), 500)
    		}
        } else {
	        saveError(ERROR_NOT_ALLOWED_IMAGE)
	    }
    }

	return (
		<div
			className={`block-editProject_head-image block-editProject_head-image-${type}`}
			style={{
				position: 'relative',
				background: currentImage 
						  ? `url(${STORAGE_URL}/${currentImage}) center/cover no-repeat` 
						  : (
						  		tempImage 
								  ? `url(${tempImage})`
								  : 'linear-gradient(90deg, #63B2E3 0%, #5C8CC9 100%)'
							)
			}}
		>
			<ImageLoader loaded={loaded} />
			<a className="btn btn-outline-secondary" onClick={handleImageUploadClick}>{btnText}</a>
            <input
                type="file"
                ref={imageUploadInputRef}
                style={{ display: 'none' }}
                onChange={handleImageUpload}
            />
		</div>
	)
}

const HeadFields = ({ params, onInputChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onInputChange({ name, value })
    }

	return (
		<div className="block-editProfile_head-fields block-editProject_head-fields">
			<div className="form-fields">
			{params.map((param, index) => {
				const { name, label, value, field } = param
				return (
					<div className="form-field" key={index}>
						{field === 'input' && <input
							type="text"
							name={name}
							placeholder={label}
							value={value}
							onChange={handleInputChange}
						/>}
						{field === 'textarea' && <textarea
							name={name}
							placeholder={label}
							value={value}
							onChange={handleInputChange}
						></textarea>}
					</div>
				)
			})}
			</div>
		</div>
	)
}

const BlockEditInfo = ({ project, mode, organization_id }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [images, setImages] = useState({
		background_image: null,
		logo_image: null,
	})

	const [data, setData] = useState({
		project_id: null,
		name: '',
		website: '',
		description: '',
	})

    const [errors, setErrors] = useState({
        name: false,
        website: false,
        description: false,
    })

	useEffect(() => {
		if (project && mode === 'edit') {
			const {
				id: project_id,
				background_image,
				logo_image,
				name,
				website,
				description,
			} = project

			setImages({
				background_image,
				logo_image,
			})

			setData({
				project_id: project_id || null,
				name: name || '',
				website: website || '',
				description: description || '',
			})
		}
	}, [project])

	const headParams = [
		{ name: 'website', label: 'adres www', value: data.website, error: errors.website, field: 'input' },
		{ name: 'name', label: 'Nazwa projektu - wpisz maks 130 znaków', value: data.name, error: errors.name, field: 'textarea' },
		{ name: 'description', label: 'Krótki opis działalności - max 280 znaków', value: data.description, error: errors.description, field: 'textarea' },
	]

	const onImageUpload = (imageData) => {
		const { type, image } = imageData
		setImageFiles({ ...imageFiles, [type]: image })
	}

	const [imageFiles, setImageFiles] = useState({
		background: null,
		logo: null,
	})

	const onInputChange = ({ name, value }) => {
		setData({ ...data, [name]: value })
        // setErrors({ ...errors, [name]: false })
	}

	const setError = (name) => {
		setErrors({ ...errors, [name]: true })
		return false
	}

	const validateForm = async (formData) => {
		try {
			await infoSchema.validate(formData, { abortEarly: false })
			return true
		} catch (errors) {
			// console.error(errors.inner)
			let error = errors.inner[0]
			return setError(error.params.path)
		}
	}

	const onSubmit = async () => {
	    const formValid = await validateForm(data)
	    if (formValid === true) {
			const payload = { ...data }
			if (mode === 'edit') {
				await dispatch(saveInfo(payload))
			} else if (mode === 'create') {
				const projectData = await dispatch(createProject({ ...payload, organization_id }))
				if (projectData) {
					const { projectId, projectSlug } = projectData
					const imagesArray = Object.entries(imageFiles)
											  .map(([type, image]) => ({ type, image }))
											  .filter(item => item.image !== null)
					for (const item of imagesArray) {
						const { type, image } = item
		        		const formData = new FormData()
				        formData.append('type', type)
				        formData.append('image', image)
				        formData.append('project_id', projectId)
		            	await dispatch(uploadImage(formData, projectId))
					}
		            await navigate(`/project/edit/${projectSlug}`)
				}
			}
		}
	}

	return (
		<form className="block block-editProfile block-editProject">
			<div className="block-editProfile_head block-editProject_head">
				<UploadImage
					image={images.logo_image}
					type="logo"
					btnText="DODAJ ZDJĘCIE AWATAR"
					projectId={data.project_id}
					mode={mode}
					onImageUpload={onImageUpload}
				/>
				<UploadImage
					image={images.background_image}
					type="background"
					btnText="DODAJ ZDJĘCIE"
					projectId={data.project_id}
					mode={mode}
					onImageUpload={onImageUpload}
				/>
				{/*<div className="block-singleProject_head-left">*/}
					{/*<div className="block-singleProject_head-image-1">*/}
						{/*<img src={getImage('logo')} width="" height="" alt="" />*/}
					{/*</div>*/}
				{/*</div>*/}
				{/*<div className="block-singleProject_head-right">
					<div className="block-singleProject_head-image-2">

					</div>
				</div>*/}
				<HeadFields params={headParams} onInputChange={onInputChange} />
			</div>
			<ConfirmButton onSubmit={onSubmit} />
		</form>
	)
}

export default BlockEditInfo
