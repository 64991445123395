import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMyCompanyOffer } from '@/actions/company'
import { useParams, useNavigate } from 'react-router-dom'
import OfferEditBlock from '@/components/Offer/OfferEditBlock'

const OfferEditPage = () => {
    const { company_slug, offer_slug } = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const loading = useSelector(state => state.company.myCompanyLoading)
	const company = useSelector(state => state.company.myCompany)
	const offer = useSelector(state => state.company.offer)

	useEffect(() => {
        if (company_slug && offer_slug) {
        	dispatch(getMyCompanyOffer(company_slug, offer_slug))
        }
	}, [company_slug, offer_slug, dispatch])

	useEffect(() => {
		if (!loading) {
			if (!company && !offer) {
				navigate('/404')
			}
		}
	}, [loading, company, offer, navigate])

	return (
		<OfferEditBlock mode="edit" company={company} offer={offer} />
	)
}

export default OfferEditPage
