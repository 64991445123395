import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMyCompanyOffers } from '@/actions/company'
import { useParams } from 'react-router-dom'
import CompanyOffers from '@/components/Offer/CompanyOffers'

const CompanyOffersPage = () => {
    const { slug } = useParams()
	const dispatch = useDispatch()

	const myCompany = useSelector(state => state.company.myCompany)
	const offers = useSelector(state => state.company.offers)

	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
        if (slug) {
        	dispatch(getMyCompanyOffers(slug))
        }
	}, [slug, dispatch])

	useEffect(() => {
		if (myCompany) {
			setTimeout(() => setLoaded(true), 500)
		}
	}, [myCompany, offers])

	if (!myCompany) return

	return <CompanyOffers company={myCompany} offers={offers} loaded={loaded} />
}

export default CompanyOffersPage
