import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { getMyOrganizationBySlug } from '@/actions/organization'
import PageLoader from '@/components/Common/Loader/PageLoader'
import BlockEditInfo from '@/components/Organization/BlockEditInfo'
import BlockEditAbout from '@/components/Organization/BlockEditAbout'
import BlockEditMembers from '@/components/Organization/BlockEditMembers'
import BlockEditProjects from '@/components/Organization/BlockEditProjects'

const OrganizationEditPage = () => {
    const { slug } = useParams()
    const { pathname } = useLocation()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [mode, setMode] = useState(null)
    const [loaded, setLoaded] = useState(false)

    const myOrganizationLoading = useSelector(state => state.organization.myOrganizationLoading)
    const myOrganization = useSelector(state => state.organization.myOrganization)

    useEffect(() => {
        if (slug) {
            dispatch(getMyOrganizationBySlug(slug))
            setMode('edit')
        } else if (pathname) {
            if (pathname.replace('/organization/', '') === 'create') {
                setMode('create')
            }
        }
    }, [slug, /*pathname, */dispatch])

    useEffect(() => {
        if (!myOrganization && !myOrganizationLoading) {
        	navigate('/404')
        } else {
            if (myOrganization || mode === 'create') {
                setTimeout(() => setLoaded(true), 500)
            }
        }
    }, [myOrganizationLoading, myOrganization, mode, navigate])

	return (
		<>
            <PageLoader loaded={loaded} />
			<BlockEditInfo organization={myOrganization} mode={mode} />
			{mode === 'edit' && (<>
                <BlockEditAbout organization={myOrganization} />
    			<BlockEditMembers organization={myOrganization} />
                <BlockEditProjects organization={myOrganization} />
            </>)}
		</>
	)
}

export default OrganizationEditPage
