import OrganizationService from '@/services/organization.service'
import { saveSuccess, saveError } from '@/utils/notify.utils'

export const GET_VIEW_ORGANIZATION_REQUEST = 'GET_VIEW_ORGANIZATION_REQUEST'
export const GET_VIEW_ORGANIZATION_SUCCESS = 'GET_VIEW_ORGANIZATION_SUCCESS'
export const GET_VIEW_ORGANIZATION_FAILURE = 'GET_VIEW_ORGANIZATION_FAILURE'
export const GET_MY_ORGANIZATIONS_SUCCESS = 'GET_MY_ORGANIZATIONS_SUCCESS'
export const GET_MY_ORGANIZATIONS_FAILURE = 'GET_MY_ORGANIZATIONS_FAILURE'
export const GET_MY_ORGANIZATION_REQUEST = 'GET_MY_ORGANIZATION_REQUEST'
export const GET_MY_ORGANIZATION_SUCCESS = 'GET_MY_ORGANIZATION_SUCCESS'
export const GET_MY_ORGANIZATION_FAILURE = 'GET_MY_ORGANIZATION_FAILURE'
export const UPLOAD_IMAGE_SUCCESS = 'UPLOAD_IMAGE_SUCCESS'
export const UPLOAD_IMAGE_FAILURE = 'UPLOAD_IMAGE_FAILURE'
export const SAVE_ORGANIZATION_SUCCESS = 'SAVE_ORGANIZATION_SUCCESS'
export const SAVE_ORGANIZATION_FAILURE = 'SAVE_ORGANIZATION_FAILURE'
// export const GET_VIEW_PROJECT_REQUEST = 'GET_VIEW_PROJECT_REQUEST'
// export const GET_VIEW_PROJECT_SUCCESS = 'GET_VIEW_PROJECT_SUCCESS'
// export const GET_VIEW_PROJECT_FAILURE = 'GET_VIEW_PROJECT_FAILURE'
// export const GET_MY_PROJECT_REQUEST = 'GET_MY_PROJECT_REQUEST'
// export const GET_MY_PROJECT_SUCCESS = 'GET_MY_PROJECT_SUCCESS'
// export const GET_MY_PROJECT_FAILURE = 'GET_MY_PROJECT_FAILURE'

export const getViewOrganizationRequest = () => ({ type: GET_VIEW_ORGANIZATION_REQUEST })
export const getViewOrganizationSuccess = (data) => ({ type: GET_VIEW_ORGANIZATION_SUCCESS, payload: data })
export const getViewOrganizationFailure = (error) => ({ type: GET_VIEW_ORGANIZATION_FAILURE, payload: error })
export const getMyOrganizationsSuccess = (data) => ({ type: GET_MY_ORGANIZATIONS_SUCCESS, payload: data })
export const getMyOrganizationsFailure = (error) => ({ type: GET_MY_ORGANIZATIONS_FAILURE, payload: error })
export const getMyOrganizationRequest = () => ({ type: GET_MY_ORGANIZATION_REQUEST })
export const getMyOrganizationSuccess = (data) => ({ type: GET_MY_ORGANIZATION_SUCCESS, payload: data })
export const getMyOrganizationFailure = (error) => ({ type: GET_MY_ORGANIZATION_FAILURE, payload: error })
export const uploadImageSuccess = () => ({ type: UPLOAD_IMAGE_SUCCESS })
export const uploadImageFailure = (error) => ({ type: UPLOAD_IMAGE_FAILURE, payload: error })
export const saveOrganizationSuccess = () => ({ type: SAVE_ORGANIZATION_SUCCESS })
export const saveOrganizationFailure = (error) => ({ type: SAVE_ORGANIZATION_FAILURE, payload: error })
// export const getViewProjectRequest = () => ({ type: GET_VIEW_PROJECT_REQUEST })
// export const getViewProjectSuccess = (data) => ({ type: GET_VIEW_PROJECT_SUCCESS, payload: data })
// export const getViewProjectFailure = (error) => ({ type: GET_VIEW_PROJECT_FAILURE, payload: error })
// export const getMyProjectRequest = () => ({ type: GET_MY_PROJECT_REQUEST })
// export const getMyProjectSuccess = (data) => ({ type: GET_MY_PROJECT_SUCCESS, payload: data })
// export const getMyProjectFailure = (error) => ({ type: GET_MY_PROJECT_FAILURE, payload: error })

export const getOrganizationById = (id) => async (dispatch) => {
  try {
    dispatch(getViewOrganizationRequest())
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getOrganizationById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getViewOrganizationSuccess(result.organization))
    } else {
      dispatch(getViewOrganizationFailure(result.message))
    }
  } catch (e) {
    dispatch(getViewOrganizationFailure(e.toString()))
  }
}

export const getOrganizationBySlug = (slug) => async (dispatch) => {
  try {
    dispatch(getViewOrganizationRequest())
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getOrganizationBySlug(slug, token)
    console.log(result)
    if (result.success) {
      dispatch(getViewOrganizationSuccess(result.organization))
    } else {
      dispatch(getViewOrganizationFailure(result.message))
    }
  } catch (e) {
    dispatch(getViewOrganizationFailure(e.toString()))
  }
}

export const getMyOrganizations = () => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getMyOrganizations(token)
    console.log(result)
    if (result.success) {
      dispatch(getMyOrganizationsSuccess(result.organizations))
    } else {
      dispatch(getMyOrganizationsFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyOrganizationsFailure(e.toString()))
  }
}

export const getMyOrganizationById = (id) => async (dispatch) => {
  try {
    dispatch(getMyOrganizationRequest())
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getMyOrganizationById(id, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyOrganizationSuccess(result.organization))
    } else {
      dispatch(getMyOrganizationFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyOrganizationFailure(e.toString()))
  }
}

export const getMyOrganizationBySlug = (slug) => async (dispatch) => {
  try {
    dispatch(getMyOrganizationRequest())
    const token = localStorage.getItem('token')
    const result = await OrganizationService.getMyOrganizationBySlug(slug, token)
    console.log(result)
    if (result.success) {
      dispatch(getMyOrganizationSuccess(result.organization))
    } else {
      dispatch(getMyOrganizationFailure(result.message))
    }
  } catch (e) {
    dispatch(getMyOrganizationFailure(e.toString()))
  }
}

export const createOrganization = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.createOrganization(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveOrganizationSuccess())
      saveSuccess()      
      return { organizationId: result.organization.id, organizationSlug: result.slug }
    } else {
      dispatch(saveOrganizationFailure(result.error))
      saveError(result.error)
      return null
    }
  } catch (e) {
    dispatch(saveOrganizationFailure(e.toString()))
    saveError(e.toString())
    return null
  }
}

export const uploadImage = (payload, organization_id) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.uploadImage(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(uploadImageSuccess())
      dispatch(getMyOrganizationById(organization_id))
      saveSuccess()
      return result.data
    } else {
      dispatch(uploadImageFailure(result.error))
      saveError(result.error)
      return null
    }
  } catch (e) {
    dispatch(uploadImageFailure(e.toString()))
    saveError(e.toString())
    return null
  }
}

export const saveInfo = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.saveInfo(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveOrganizationSuccess())
      dispatch(getMyOrganizationById(payload.organization_id))
      saveSuccess()
    } else {
      dispatch(saveOrganizationFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveOrganizationFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveAbout = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.saveAbout(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveOrganizationSuccess())
      dispatch(getMyOrganizationById(payload.organization_id))
      saveSuccess()
    } else {
      dispatch(saveOrganizationFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveOrganizationFailure(e.toString()))
    saveError(e.toString())
  }
}

export const saveMembers = (payload) => async (dispatch) => {
  try {
    const token = localStorage.getItem('token')
    const result = await OrganizationService.saveMembers(payload, token)
    console.log(result)
    if (result.success) {
      dispatch(saveOrganizationSuccess())
      dispatch(getMyOrganizationById(payload.organization_id))
      saveSuccess()
    } else {
      dispatch(saveOrganizationFailure(result.error))
      saveError(result.error)
    }
  } catch (e) {
    dispatch(saveOrganizationFailure(e.toString()))
    saveError(e.toString())
  }
}
