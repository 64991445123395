import api from './api'

class UserService
{
	async setAccountType(payload, token) {
		try {
			const response = await api.post('/user/set-account-type', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][setAccountType][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async uploadImage(payload, token) {
		try {
			const response = await api.post('/user/upload-image', payload, {
	            headers: { 
	            	Authorization: `Bearer ${token}`,
	            	'Content-Type': 'multipart/form-data'
	            }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][uploadImage][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveProfile(payload, token) {
		try {
			const response = await api.post('/user/save-profile', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][saveProfile][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveAbout(payload, token) {
		try {
			const response = await api.post('/user/save-about', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][saveAbout][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveInterests(payload, token) {
		try {
			const response = await api.post('/user/save-interests', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][saveInterests][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async deleteInterest(payload, token) {
		try {
			const response = await api.post('/user/delete-interest', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][deleteInterest][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveWorks(payload, token) {
		try {
			const response = await api.post('/user/save-works', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][saveWorks][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveProjects(payload, token) {
		try {
			const response = await api.post('/user/save-projects', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][saveProjects][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveAchievements(payload, token) {
		try {
			const response = await api.post('/user/save-achievements', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][saveAchievements][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async saveSkills(payload, token) {
		try {
			const response = await api.post('/user/save-skills', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][saveSkills][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async deleteSkill(payload, token) {
		try {
			const response = await api.post('/user/delete-skill', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][deleteSkill][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async confirmSkill(payload, token) {
		try {
			const response = await api.post('/user/confirm-skill', payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][confirmSkill][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getUserById(id, token) {
		try {
			const response = await api.get(`/user/${id}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][getUserById][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getUserBySlug(slug, token) {
		try {
			const response = await api.get(`/user/${slug}`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][getUserBySlug][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async getUsers(token) {
		try {
			const response = await api.get(`/users/all`, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][getUsers][error]', e)
			return { success: false, message: e.toString() }
		}
	}

	async searchUsers(payload, token) {
		try {
			const response = await api.post(`/users/search`, payload, {
	            headers: { Authorization: `Bearer ${token}` }
	        })
			return response.data
		} catch (e) {
			console.log('[UserService][searchUsers][error]', e)
			return { success: false, message: e.toString() }
		}
	}
}

export default new UserService()
