import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getMyOrganizationBySlug } from '@/actions/organization'
import { getTransactionDirections, getDocumentTypes, getMyDocuments } from '@/actions/manage'
import PageLoader from '@/components/Common/Loader/PageLoader'
import ManageBlock from '@/components/Manage/ManageBlock'

const OrganizationManagePage = () => {
    const { slug } = useParams()
	const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loaded, setLoaded] = useState(false)

    const myOrganizationLoading = useSelector(state => state.organization.myOrganizationLoading)
    const myOrganization = useSelector(state => state.organization.myOrganization)

	useEffect(() => {
        if (slug) dispatch(getMyOrganizationBySlug(slug))
        dispatch(getTransactionDirections())
        dispatch(getDocumentTypes())
	}, [slug, dispatch])

    useEffect(() => {
        if (!myOrganization && !myOrganizationLoading) {
        	navigate('/404')
        } else {
            if (myOrganization) {
                dispatch(getMyDocuments(myOrganization.id))
                setTimeout(() => setLoaded(true), 500)
            }
        }
    }, [myOrganizationLoading, myOrganization, navigate, dispatch])

	return (
		<>
            <PageLoader loaded={loaded} />
		    <ManageBlock organization={myOrganization} />
        </>
	)
}

export default OrganizationManagePage
