import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getCompanyBySlug } from '@/actions/company'
import PageLoader from '@/components/Common/Loader/PageLoader'
import BlockInfo from '@/components/Company/BlockInfo'
import BlockAbout from '@/components/Company/BlockAbout'
import BlockMembers from '@/components/Common/Carousel/BlockMembers'
import BlockOffers from '@/components/Company/BlockOffers'
import BlockProjects from '@/components/Company/BlockProjects'

const CompanyProfilePage = () => {
    const { slug } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loaded, setLoaded] = useState(false)

    const viewCompanyLoading = useSelector(state => state.company.viewCompanyLoading)
    const viewCompany = useSelector(state => state.company.viewCompany)

    useEffect(() => {
        if (slug) dispatch(getCompanyBySlug(slug))
    }, [slug, dispatch])

    useEffect(() => {
        if (!viewCompany && !viewCompanyLoading) {
        	navigate('/404')
        } else {
            if (viewCompany) setTimeout(() => setLoaded(true), 500)
        }
    }, [viewCompanyLoading, viewCompany, navigate])

	return (
		<>
            <PageLoader loaded={loaded} />
			<BlockInfo company={viewCompany} />
			<BlockAbout company={viewCompany} />
			<BlockMembers entity={viewCompany} title="zespół" />
			<BlockOffers company={viewCompany} mode="profile" />
			<BlockProjects company={viewCompany} />
		</>
	)
}

export default CompanyProfilePage
