import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { confirmSkill } from '@/actions/user'
import { Link } from 'react-router-dom'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'

import './styles.css'

const BlockSkills = ({ user, isOwn, onEdit }) => {
	const dispatch = useDispatch()

	const [skills, setSkills] = useState([])

	useEffect(() => {
		if (user) {
			setSkills(user.skills)
		}
	}, [user])

	const [open, setOpen] = useState(false)

	const toggleContent = () => {
		setOpen(!open)
	}

	const getImage = (avatar_image) => {
		if (avatar_image) {
			return `${STORAGE_URL}/${avatar_image}` 
		}
		return userDefault
	}

	const showConfirmsCount = (count) => {
		let className = 'block-skills-item_peoples-count'
		let showCount = count

		if (count > 7) {
			className += ' yellow-2 big'
			showCount = `+${count - 7}`
		} else if (count >= 4) {
			className += ' yellow'
		}

		return <span className={className}>{showCount}</span>
	}

	const handleItemClick = (key) => {
		if (!isOwn) {
			dispatch(confirmSkill({ user_id: user.id, slug: user.profile.slug, key }))
		}
	}

	return (
		<div className="block block-skills">
			{isOwn && <div className="block-edit">
				{/*<Link to="/user/edit?block=skills">edytuj<span></span></Link>*/}
				<a style={{ cursor: 'pointer' }} onClick={onEdit}>edytuj<span></span></a>
			</div>}
			<div className="block-wrap">
				<div className="block-heading yellow">
					<h2>Umiejętności</h2>
				</div>
				<div className={`block-skills-items${open ? ' show' : ''}`}>
					{skills.map((skill, si) => {
						const { key, content, confirms } = skill
						return (
							<div
								style={{ cursor: 'pointer' }}
								className="block-skills-item"
								key={si}								
							>
								<p
									className="block-skills-item_title"
									onClick={() => handleItemClick(key)}
								>{content}</p>
								<span className="block-skills-item_sep"></span>
								<div className="block-skills-item_peoples">
									{confirms.map((confirm, ci) => {
										const { slug, avatar_image } = confirm.user
										return ci < 7 ? (
											<Link
												className="block-skills-item_photo"
												to={`/user/profile/${slug}`}
												key={ci}
											>
												<img src={getImage(avatar_image)} width="" height="" alt="" />
											</Link>
										) : null
									})}
									{showConfirmsCount(confirms.length)}
								</div>
							</div>
						)
					})}
					{skills.length === 0 && <span>(brak danych)</span>}
					{skills.length > 4 && <div className="block-skills-more">
						<a
							style={{ cursor: 'pointer' }}
							onClick={toggleContent}
						>
							{!open && <span>rozwiń</span>}
							{open && <span>zwiń</span>}
						</a>
					</div>}
				</div>
			</div>
		</div>
	)
}

export default BlockSkills
