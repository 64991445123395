import { toast } from 'react-toastify'

export const saveSuccess = (message = null) => {
    if (message) toast.success(message)
    else toast.success('Zapisane!')
}

export const saveError = (message) => {
    toast.error(message)
}
