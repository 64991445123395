import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getMyCompanyBySlug } from '@/actions/company'
import { useParams, useNavigate } from 'react-router-dom'
import OfferEditBlock from '@/components/Offer/OfferEditBlock'

const OfferCreatePage = () => {
    const { slug } = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const loading = useSelector(state => state.company.myCompanyLoading)
	const company = useSelector(state => state.company.myCompany)

	useEffect(() => {
        if (slug) dispatch(getMyCompanyBySlug(slug))
	}, [slug, dispatch])

	useEffect(() => {
		if (!loading) {
			if (!company) {
				navigate('/404')
			}
		}
	}, [loading, company, navigate])

	return (
		<OfferEditBlock mode="create" company={company} />
	)
}

export default OfferCreatePage
