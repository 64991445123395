import * as yup from 'yup'

export const infoSchema = yup.object().shape({
  name: yup.string()
    .required(),
  website: yup.string()
    .required(),
  description: yup.string()
    .required()
    .max(280),
})

export const aboutSchema = yup.object().shape({
  about: yup.string()
    .required()
    .max(2000),
})
