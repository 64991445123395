import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { createOrganization, uploadImage, saveInfo } from '@/actions/organization'
import { checkImageType, ERROR_NOT_ALLOWED_IMAGE } from '@/utils/upload.utils'
import { saveError } from '@/utils/notify.utils'
import { STORAGE_URL } from '@/constants/api'
import ImageLoader from '@/components/Common/Loader/ImageLoader'

import student from '@/assets/images/student.svg'
import muscle from '@/assets/images/muscle.svg'

import {
	Instagram,
	Facebook,
	Youtube,
	Tiktok,
	Linkedin
} from '@/components/Common/Social/SocialIcons'

import { infoSchema } from '@/validations/institution.schema'

import ConfirmButton from '@/components/Common/Edit/ConfirmButton'

import './styles.css'

const UploadBackgroundImage = ({ backgroundImage, organizationId, mode, onImageUpload }) => {
	const dispatch = useDispatch()
    const imageUploadInputRef = useRef()

    const [currentImage, setCurrentImage] = useState(null)
    const [tempImage, setTempImage] = useState(null)
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
    	if (backgroundImage) {
    		setCurrentImage(backgroundImage)
    	}
    }, [backgroundImage])

    const handleImageUploadClick = () => {
        imageUploadInputRef.current.click()
    }

    const handleImageUpload = async (e) => {
        const image = e.target.files[0]
        if (checkImageType(image.type)) {
    		setLoaded(false)
            if (mode === 'edit') {
		        const formData = new FormData()
		        formData.append('type', 'background')
		        formData.append('image', image)
		        formData.append('organization_id', organizationId)
            	const imageData = await dispatch(uploadImage(formData, organizationId))
    			setCurrentImage(imageData.image)
    			setTimeout(() => setLoaded(true), 500)
    		} else {
    			setTempImage(URL.createObjectURL(image))
    			onImageUpload({ type: 'background', image })
    			setTimeout(() => setLoaded(true), 500)
    		}
        } else {
	        saveError(ERROR_NOT_ALLOWED_IMAGE)
	    }
    }

	return (
		<div
			className="block-wallpaper block-editService_wallpaper"
			style={{
				background: currentImage 
						  ? `url(${STORAGE_URL}/${currentImage}) center/cover no-repeat` 
						  : (
						  		tempImage 
								  ? `url(${tempImage})`
								  : 'linear-gradient(90deg, #63B2E3 0%, #5C8CC9 100%)'
							)
			}}
		>
			<ImageLoader loaded={loaded} />
			<a className="btn btn-outline-secondary" onClick={handleImageUploadClick}>DODAJ ZDJĘCIE</a>
            <input
                type="file"
                ref={imageUploadInputRef}
                style={{ display: 'none' }}
                onChange={handleImageUpload}
            />
		</div>
	)
}

const UploadLogoImage = ({ logoImage, organizationId, mode, onImageUpload }) => {
	const dispatch = useDispatch()
    const imageUploadInputRef = useRef()

    const [currentImage, setCurrentImage] = useState(null)
    const [tempImage, setTempImage] = useState(null)
    const [loaded, setLoaded] = useState(true)

    useEffect(() => {
    	if (logoImage) {
    		setCurrentImage(logoImage)
    	}
    }, [logoImage])

    const handleImageUploadClick = () => {
        imageUploadInputRef.current.click()
    }

    const handleImageUpload = async (e) => {
        const image = e.target.files[0]
        if (checkImageType(image.type)) {
    		setLoaded(false)
            if (mode === 'edit') {
		        const formData = new FormData()
		        formData.append('type', 'logo')
		        formData.append('image', image)
		        formData.append('organization_id', organizationId)
	            const imageData = await dispatch(uploadImage(formData, organizationId))
	    		setCurrentImage(imageData.image)
    			setTimeout(() => setLoaded(true), 500)
    		} else {
    			setTempImage(URL.createObjectURL(image))
    			onImageUpload({ type: 'logo', image })
    			setTimeout(() => setLoaded(true), 500)
    		}
        } else {
	        saveError(ERROR_NOT_ALLOWED_IMAGE)
	    }
    }

	return (
		<div
			className="block-editService_head-photo"
			style={{				
				background: currentImage 
						  ? `url(${STORAGE_URL}/${currentImage}) center/cover no-repeat`
						  : (
						  		tempImage 
								  ? `url(${tempImage})`
								  : 'linear-gradient(180deg, #5C8CC9 0%, #63B2E3 100%)'
							)
			}}
		>
			<ImageLoader loaded={loaded} />
			<a className="btn btn-outline-secondary" onClick={handleImageUploadClick}>DODAJ ZDJĘCIE AWATAR</a>
            <input
                type="file"
                ref={imageUploadInputRef}
                style={{ display: 'none' }}
                onChange={handleImageUpload}
            />
		</div>
	)
}

const HeadFields = ({ params, onInputChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onInputChange({ name, value })
    }

	return (
		<div className="block-editService_head-fields">
			<div className="form-fields">
			{params.map((param, index) => {
				const { name, label, value, field, error } = param
				return (
					<div className={`form-field ${error ? ' form-field-error' : ''}`} key={index}>
						{field === 'input' && <input
							type="text"
							name={name}
							placeholder={label}
							value={value}
							onChange={handleInputChange}
						/>}
						{field === 'textarea' && <textarea
							name={name}
							placeholder={label}
							value={value}
							onChange={handleInputChange}
						></textarea>}
						{error && <span className="form-field-icon">!</span>}
					</div>
				)
			})}
			</div>
		</div>
	)
}

const WorkFields = ({ params, onInputChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onInputChange({ name, value })
    }

	return (
		<div className="block-editProfile_listIcons block-editService_listIcons">
			<div className="form-fields">
				{params.map((param, index) => {
					const { name, icon, label, field, value, error } = param
					return (
						<div className={`form-field ${error ? ' form-field-error' : ''}`} key={index}>
							<div className="block-editProfile_listIcons-item">
								<span className="block-editProfile_listIcons-item_label">
									<span className="block-editProfile_listIcons-item_label-img">
										<img src={icon} width="" height="" alt="" />
									</span>
									{label}
								</span>
								<span className="block-editProfile_listIcons-item_value">
									{field === 'input' && 
										<input
											type="text"
											name={name}
											placeholder="wpisz"
											value={value}
											onChange={handleInputChange}
										/>
									}
									{field === 'textarea' && 
										<textarea
											name={name}
											placeholder="wpisz"
											value={value}
											onChange={handleInputChange}
										></textarea>
									}
									{error && <span className="form-field-icon">!</span>}
								</span>
							</div>
						</div>
					)
				})}
			</div>
		</div>

	)
}

const SocialFields = ({ params, onInputChange }) => {
    const handleInputChange = (e) => {
        const { name, value } = e.target
        onInputChange({ name, value })
    }

	return (
		<div className="block-editProfile_socIcons block-editService_socIcons">
			<div className="form-fields">
				{params.map((param, index) => {
					const { name, icon, value } = param
					return (
						<div className="form-field" key={index}>
							<div className="block-editProfile_socIcons-item">
								<span className="block-editProfile_socIcons-item_label">{icon}</span>
								<span className="block-editProfile_socIcons-item_value">
									<input
										type="text"
										name={name}
										placeholder="wklej adres"
										value={value}
										onChange={handleInputChange}
									/>
								</span>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	)
}

const BlockEditInfo = ({ organization, mode }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const [images, setImages] = useState({
		background_image: null,
		logo_image: null,
	})

	const [data, setData] = useState({
		organization_id: null,
		name: '',
		website: '',
		description: '',
		activity_type: '',
		specialization: '',
	})

	const [socials, setSocials] = useState({})

    const [errors, setErrors] = useState({
        name: false,
        website: false,
        description: false,
        activity_type: false,
        specialization: false,
    })

	useEffect(() => {
		if (organization && mode === 'edit') {
			const {
				id: organization_id,
				background_image,
				logo_image,
				name,
				website,
				description,
				activity_type,
				specialization,
			} = organization

			setImages({
				background_image,
				logo_image,
			})

			setData({
				organization_id: organization_id || null,
				name: name || '',
				website: website || '',
				description: description || '',
				activity_type: activity_type || '',
				specialization: specialization || '',
			})

			setSocials(organization.socials.reduce((acc, item) => {
				acc[item.platform] = item.url
				return acc
			}, {}))
		}
	}, [organization, mode])

	const headParams = [
		{ name: 'name', label: 'Nazwa organizacji', value: data.name, error: errors.name, field: 'input' },
		{ name: 'website', label: 'adres www', value: data.website, error: errors.website, field: 'input' },
		{ name: 'description', label: 'Krótki opis działalności - max 280 znaków', value: data.description, error: errors.description, field: 'textarea' },
	]

	const workParams = [
		{ name: 'activity_type', icon: student, label: 'Rodzaj działalności', field: 'input', value: data.activity_type, error: errors.activity_type },
		{ name: 'specialization', icon: muscle, label: 'Specjalizacja', field: 'input', value: data.specialization, error: errors.specialization },
	]

	const socialParams = [
		{ name: 'instagram', icon: <Instagram />, value: socials.instagram || '' },
		{ name: 'facebook', icon: <Facebook />, value: socials.facebook || '' },
		{ name: 'youtube', icon: <Youtube /> , value: socials.youtube || ''},
		{ name: 'tiktok', icon: <Tiktok />, value: socials.tiktok || '' },
		{ name: 'linkedin', icon: <Linkedin />, value: socials.linkedin || '' },
	]

	const [imageFiles, setImageFiles] = useState({
		background: null,
		logo: null,
	})

	const onImageUpload = (imageData) => {
		const { type, image } = imageData
		setImageFiles({ ...imageFiles, [type]: image })
	}

	const onInputChange = ({ name, value }) => {
		setData({ ...data, [name]: value })
        setErrors({ ...errors, [name]: false })
	}

	const onSocialChange = ({ name, value }) => {
		setSocials({ ...socials, [name]: value })
        // setErrors({ ...errors, [name]: false })
	}

	const setError = (name) => {
		setErrors({ ...errors, [name]: true })
		return false
	}

	const validateForm = async (formData) => {
		try {
			await infoSchema.validate(formData, { abortEarly: false })
			return true
		} catch (errors) {
			// console.error(errors.inner)
			let error = errors.inner[0]
			return setError(error.params.path)
		}
	}

	const onSubmit = async () => {
	    const formValid = await validateForm(data)
	    if (formValid === true) {
			const payload = { ...data, socials }
			if (mode === 'edit') {
				await dispatch(saveInfo(payload))
			} else if (mode === 'create') {
				const organizationData = await dispatch(createOrganization(payload))
				if (organizationData) {
					const { organizationId, organizationSlug } = organizationData
					const imagesArray = Object.entries(imageFiles)
											  .map(([type, image]) => ({ type, image }))
											  .filter(item => item.image !== null)
					for (const item of imagesArray) {
						const { type, image } = item
		        		const formData = new FormData()
				        formData.append('type', type)
				        formData.append('image', image)
				        formData.append('organization_id', organizationId)
		            	await dispatch(uploadImage(formData, organizationId))
					}
		            await navigate(`/organization/edit/${organizationSlug}`)
				}
			}
		}
	}

	return (
		<form className="block block-editProfile">
			<UploadBackgroundImage
				backgroundImage={images.background_image}
				organizationId={data.organization_id}
				mode={mode}
				onImageUpload={onImageUpload}
			/>
			<div className="block-editProfile_head">
				<UploadLogoImage
					logoImage={images.logo_image}
					organizationId={data.organization_id}
					mode={mode}
					onImageUpload={onImageUpload}
				/>
				<HeadFields
					params={headParams.filter(param => param.field === 'input')}
					onInputChange={onInputChange}
				/>
			</div>
			<div className="block-editProfile_head">
				<HeadFields
					params={headParams.filter(param => param.field === 'textarea')}
					onInputChange={onInputChange}
				/>
			</div>
			<WorkFields params={workParams} onInputChange={onInputChange} />
			<SocialFields params={socialParams} onInputChange={onSocialChange} />
			<ConfirmButton onSubmit={onSubmit} />
		</form>
	)
}

export default BlockEditInfo
