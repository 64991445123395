import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getProjectBySlug } from '@/actions/project'
import PageLoader from '@/components/Common/Loader/PageLoader'
import BlockInfo from '@/components/Project/BlockInfo'
import BlockAbout from '@/components/Project/BlockAbout'
import BlockGoals from '@/components/Project/BlockGoals'
import BlockSupport from '@/components/Project/BlockSupport'
import BlockMembers from '@/components/Common/Carousel/BlockMembers'
import BlockStages from '@/components/Project/BlockStages'

const ProjectProfilePage = () => {
    const { slug } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loaded, setLoaded] = useState(false)

    const viewProjectLoading = useSelector(state => state.organization.viewProjectLoading)
    const viewProject = useSelector(state => state.organization.viewProject)

    useEffect(() => {
        if (slug) dispatch(getProjectBySlug(slug))
    }, [slug, dispatch])

    useEffect(() => {
        if (!viewProject && !viewProjectLoading) {
        	navigate('/404')
        } else {
            if (viewProject) setTimeout(() => setLoaded(true), 500)
        }
    }, [viewProjectLoading, viewProject, navigate])

	return (
		<>
            <PageLoader loaded={loaded} />
			<BlockInfo project={viewProject} />
			<BlockAbout project={viewProject} />
			<BlockGoals project={viewProject} />
			<BlockSupport project={viewProject} />
			<BlockMembers entity={viewProject} title="zespół projektu" />
			<BlockStages project={viewProject} />
		</>
	)
}

export default ProjectProfilePage
