import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getUserBySlug } from '@/actions/user'
import PageLoader from '@/components/Common/Loader/PageLoader'
import BlockUser from '@/components/User/BlockUser'
import BlockAbout from '@/components/User/BlockAbout'
import BlockInterests from '@/components/User/BlockInterests'
import BlockWorks from '@/components/User/BlockWorks'
import BlockProjects from '@/components/User/BlockProjects'
import BlockAchievements from '@/components/User/BlockAchievements'
import BlockSkills from '@/components/User/BlockSkills'

const UserProfilePage = () => {
    const { slug } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    
    const viewUserLoading = useSelector(state => state.user.viewUserLoading)
    const viewUser = useSelector(state => state.user.viewUser)

    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        if (slug) dispatch(getUserBySlug(slug))
    }, [slug, dispatch])

    useEffect(() => {
        if (!viewUser && !viewUserLoading) {
        	navigate('/404')
        } else {
        	if (viewUser)  setTimeout(() => setLoaded(true), 500)
        }
    }, [viewUserLoading, viewUser, navigate])

	const onEdit = () => {
		
	}

	return (
		<>
			<PageLoader loaded={loaded} />
			<BlockUser user={viewUser} isOwn={false} onEdit={onEdit} />
			<BlockAbout user={viewUser} isOwn={false} onEdit={onEdit} />
			<BlockInterests user={viewUser} isOwn={false} onEdit={onEdit} />
			<BlockWorks user={viewUser} isOwn={false} onEdit={onEdit} />
			<BlockProjects user={viewUser} isOwn={false} onEdit={onEdit} />
			<BlockAchievements user={viewUser} isOwn={false} onEdit={onEdit} />
			<BlockSkills user={viewUser} isOwn={false} onEdit={onEdit} />
		</>
	)
}

export default UserProfilePage
