import { Link } from 'react-router-dom'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'
import icon1 from '@/assets/images/offers/icon-1.svg'
import icon2 from '@/assets/images/offers/icon-2.svg'
import { workSystems, contractTypes } from '@/constants/offer'

const OfferItem = ({ company, offer, mode }) => {
	const getImage = (logo_image) => {
		if (logo_image) {
			return `${STORAGE_URL}/${logo_image}` 
		}
		return userDefault
	}

	return (
		<div className="block-offers-item">
			<p className="block-offers-item_head"><span>staż</span>oferta reguralna</p>
			<div className="block-offers-item_image">
				<img src={getImage(company.logo_image)} width="" height="" alt="" />
			</div>
			<div className="block-offers-item_info">
				<p className="block-offers-item_company">{company.name}</p>
				<h3 className="block-offers-item_title">{offer.title}</h3>
				<p className="block-offers-item_city">{offer.city}, {offer.region}</p>
				<div className="block-offers-item_advantages">
					<p><img src={icon1} width="" height="" alt="" />{workSystems[offer.work_system]}</p>
					<p><img src={icon2} width="" height="" alt="" />{contractTypes[offer.contract_type]}</p>
				</div>
				<div className="block-offers-item_data">
					{offer.specializations.map((item, index) => <p key={index}>{item}</p>)}
				</div>
				<div className="block-offers-item_button">
					{mode === 'profile' && (
						<Link
							to={`/offer/${offer.slug}/view`}
							className="btn btn-sm btn-secondary"
						>
							OBEJRZYJ OFERTĘ
						</Link>
					)}
					{(mode === 'dashboard' || mode === 'edit') && (
						<Link
							to={`/offers/company/${company.slug}/edit/${offer.slug}`}
							className="btn btn-sm btn-secondary"
						>
							EDYTUJ OFERTĘ
						</Link>
					)}
				</div>
			</div>
		</div>
	)
}

export default OfferItem
