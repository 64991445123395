import { useState, useEffect } from 'react'
import { STORAGE_URL } from '@/constants/api'
import userDefault from '@/assets/images/user-default.png'

const ChatUserItem = ({ position, user, isSelected, isUnread, selectUser }) => {
	const [image, setImage] = useState(null)
	const [data, setData] = useState({ user_id: null, firstname: '', lastname: '', slug: null })

	useEffect(() => {
		if (user) {
			const { user_id, avatar_image, firstname, lastname, slug } = user
			setImage(getImage(avatar_image))
			setData({ user_id, firstname, lastname, slug })
		}
	}, [user])

	const getImage = (avatar_image) => {
		if (avatar_image) {
			return `${STORAGE_URL}/${avatar_image}` 
		}
		return userDefault
	}

	const handleClick = () => {
		if (position === 'users') {
			selectUser()
		} else if (position === 'dialog') {
			window.open(`/user/profile/${data.slug}`, '_blank', 'noopener,noreferrer')
		}
	}

	return (
		<div
			className={`block-chat-user-item ${isSelected ? 'selected' : ''} ${isUnread ? 'unread' : ''}`}
			onClick={handleClick}
		>
			<div>
				<div className="block-chat-user-item_photo">
					<span>{image && <img src={image} width="" height="" alt="" />}</span>
				</div>
				<p>{data.firstname} {data.lastname}</p>
			</div>
			{position === 'users' && isUnread && <div className="block-chat-user-item-unread"></div>}
		</div>
	)
}

export default ChatUserItem
